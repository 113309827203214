import { useState, useEffect } from 'react'
import { IconReceipt2, IconReceiptOff, IconCoinOff, IconCoin } from '@tabler/icons-react'
import { Tooltip } from '@mantine/core'

import Style from './billabletaxoption.tailwind'

export const BillableTaxOptionSwitch = (props) => {
    const { handleToggleBillable, handleToggleTax, transaction, transactions } = props
    const [billabletax, setBillabletax] = useState([])

    function handleToggleBillableMethod(transaction) {
        const editedPayload = JSON.parse(localStorage.getItem('singleUpdatePayload'))?.find((t) => t._id === transaction?._id)
        handleToggleBillable(transaction, editedPayload)
        if (!transaction?.billable || (transaction?.currency === 'USD' && !transaction?.customer?.id && !editedPayload?.customer?.id) || (transaction?.currency === 'CAD' && !transaction?.customer?.id && !editedPayload?.customer?.id)) {
            return
        }
        const editedTransaction = JSON.parse(localStorage.getItem('singleUpdatePayload'))?.find((t) => t._id === transaction?._id)
        setBillabletax((prev) => [
            ...prev.filter((p) => p._id !== transaction?._id),
            { _id: transaction?._id, billable: editedTransaction?.billable || transaction?.billable, tax: { id: editedTransaction?.tax?.id || transaction?.tax?.id, value: editedTransaction?.tax?.value || transaction?.tax?.value } },
        ])
    }

    function handleToggleTaxMethod(transaction) {
        handleToggleTax(transaction)
        if (!transaction?.tax?.id) {
            return
        }

        // USE ONLY FOR USD
        if (transaction?.currency === 'USD') {
            const editedTransaction = JSON.parse(localStorage.getItem('singleUpdatePayload'))?.find((t) => t._id === transaction?._id)
            setBillabletax((prev) => [
                ...prev.filter((p) => p._id !== transaction?._id),
                { _id: transaction?._id, billable: editedTransaction?.billable || transaction?.billable, tax: { id: editedTransaction?.tax?.id || transaction?.tax?.id, value: editedTransaction?.tax?.value || transaction?.tax?.value } },
            ])
        }
    }

    /* eslint-disable */
    useEffect(() => {
        setBillabletax(
            transactions.map((transaction) => {
                const editedTransaction = JSON.parse(localStorage.getItem('singleUpdatePayload'))?.find((t) => t?._id === transaction?._id)
                if (editedTransaction) return { ...editedTransaction, billable: editedTransaction?.billable || transaction?.billable, tax: { id: editedTransaction?.tax?.id || transaction?.tax?.id, value: editedTransaction?.tax?.value || transaction?.tax?.value } }
                else return { _id: transaction?._id, billable: transaction?.billable, tax: { id: transaction?.tax?.id, value: transaction?.tax?.value } }
            })
        )
    }, [localStorage.getItem('singleUpdatePayload')])

    return (
        <div className={!props?.view ? `${Style.container} justify-center` : Style.container}>
            <>
                {billabletax.find((p) => p?._id === transaction?._id) && billabletax.find((p) => p?._id === transaction?._id)?.billable === 'Billable' ? (
                    <Tooltip label='Billable' color='cyan' withArrow>
                        <IconReceipt2 color='rgb(34 197 94)' className='cursor-pointer' onClick={() => handleToggleBillableMethod(transaction)} />
                    </Tooltip>
                ) : (
                    <Tooltip label='Billable' color='cyan' withArrow>
                        <IconReceiptOff color='gray' onClick={() => handleToggleBillableMethod(transaction)} />
                    </Tooltip>
                )}

                {billabletax.find((p) => p?._id === transaction?._id) && billabletax.find((p) => p?._id === transaction?._id)?.tax?.id && billabletax.find((p) => p?._id === transaction?._id)?.tax?.id !== 'NON' ? (
                    <Tooltip label='Tax' color='cyan' withArrow>
                        <IconCoin color='rgb(34 197 94)' className='cursor-pointer' onClick={() => handleToggleTaxMethod(transaction)} />
                    </Tooltip>
                ) : (
                    <Tooltip label='Tax' color='cyan' withArrow>
                        <IconCoinOff color='gray' onClick={() => handleToggleTaxMethod(transaction)} />
                    </Tooltip>
                )}
            </>
        </div>
    )
}
