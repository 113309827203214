import { useContext, useEffect } from 'react'
import { TextInput, Select } from '@mantine/core'
import { IconTrashX } from '@tabler/icons-react'

import { ClientButton, ViewContext } from 'components/lib'
import { ApiContext } from 'context/api'

export function ModalForm(props) {
    const viewContext = useContext(ViewContext)
    const { setAdvSearchValue, advSearchValue, company, client, status, activity, setCompany, setClient, setStatus, setActivity } = useContext(ApiContext)

    function handleClearField(props, type) {
        type === 'status' && setStatus('')
        type === 'activity' && setActivity('')
    }

    async function handleSearch() {
        setAdvSearchValue({ ...advSearchValue, company, client, activity, status })
        viewContext.modal.hide()
    }

    async function handleReset() {
        setCompany('')
        setClient('')
        setStatus('')
        setActivity('')
        setAdvSearchValue({})
        viewContext.modal.hide()
    }

    /* eslint-disable */
    useEffect(() => {}, [])

    return (
        <div className='pb-8'>
            <div>
                <TextInput name='company' value={company} onChange={(e) => setCompany(e.target.value)} label='Company:' placeholder='Company Name' />
            </div>

            <div>
                <TextInput name='client' value={client} onChange={(e) => setClient(e.target.value)} label='Client:' placeholder='Client Name' />
            </div>

            <div className='flex align-items gap-2'>
                <Select
                    data={[
                        { value: 'not_active', label: 'Not Active' },
                        { value: 'invited', label: 'Invited' },
                        { value: 'active', label: 'Active' },
                    ]}
                    value={status}
                    onChange={(val) => setStatus(val)}
                    label='Status:'
                    placeholder='Select status'
                    styles={{ root: { width: '100%' } }}
                />
                <span onClick={(val) => handleClearField(val, 'status')} id='status' className='flex flex-col justify-center mt-5 cursor-pointer'>
                    <IconTrashX size='1.2rem' />
                </span>
            </div>

            <div className='flex align-items gap-2'>
                <Select
                    data={[
                        { value: 'needs_review', label: 'Need Review' },
                        { value: 'uncategorized_transactions', label: 'Uncategorized Transactions' },
                        { value: 'no_action_needed', label: 'No Action Needed' },
                        { value: 'disconnected', label: 'Disconnected' },
                    ]}
                    value={activity}
                    onChange={(val) => setActivity(val)}
                    label='Activity:'
                    placeholder='Select activity'
                    styles={{ root: { width: '100%' } }}
                />
                <span onClick={(val) => handleClearField(val, 'activity')} id='activity' className='flex flex-col justify-center mt-5 cursor-pointer'>
                    <IconTrashX size='1.2rem' />
                </span>
            </div>

            <div className='mt-2'>
                <ClientButton icon='' label='Search' bg='bg-cyan-500' color='text-white' callback={handleSearch} />
                <ClientButton icon='' label='Reset' bg='bg-red-500' color='text-white' callback={handleReset} />
            </div>
        </div>
    )
}
