import { useState, useEffect } from 'react'
import { Select } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'

import Style from './option.tailwind'

export const ClientOption = (props) => {
    const [value, setValue] = useState('')
    const { width } = useViewportSize()
    const dynamicWidth = width >= 1024 && width <= 1500 ? { width: 'unset', cursor: 'pointer' } : { width: '100%', cursor: 'pointer' }

    const data = props?.list
        .map((data) => {
            return {
                value: data.id,
                label: data.value,
            }
        })
        .filter((l) => l.value && l.label)
        .sort(function (a, b) {
            if (a.label > b.label) return 1
            if (a.label < b.label) return -1
            return 0
        })

    function handleOnChange(props) {
        props?.callback(props)
        setValue(props?.value)
    }

    /* eslint-disable */
    useEffect(() => {
        setValue(props?.id || value)
    }, [])

    return (
        <div className={Style.container}>
            <Select
                readOnly={(props?.fetchTransactionType === 'underReview' || props?.fetchTransactionType === 'approved') && (props?.permission === 'user' || props?.permission === 'clientStaff')}
                disabled={props?.fetchTransactionType === 'approved'}
                // value={props?.vendor && props?.transaction?.transType !== 'Deposit' ? props?.id || value : value || props?.id}
                value={props?.id || value}
                searchable
                onChange={(value) => handleOnChange({ value, ...props })}
                nothingFound='No options'
                data={data}
                placeholder={props?.placeholder}
                styles={{ root: { width: '100%' }, input: dynamicWidth }}
            />
        </div>
    )
}
