import { useEffect, useContext } from 'react'
import { Tooltip, Skeleton, LoadingOverlay } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'

import '@fortawesome/fontawesome-free/css/all.min.css'
import { AuthContext, ViewContext, Animate, Link, ClientButton, Icon, ClientStyledData, AdminActionButtons, useNavigate, ClientStepper, Table, ClientPagination, MagicLinkGenerator, Tree } from 'components/lib'
import { ErrorNotification, UpdateNotification } from 'utils/notifications'
import { ApiContext } from 'context/api'

export function CompanyDashboard() {
    return <TableView />
}

function TableView() {
    const authContext = useContext(AuthContext)
    const viewContext = useContext(ViewContext)
    const {
        handleFetchCompaniesRequest,
        handleFetchCompaniesCountRequest,
        handleFetchCompanyProfileRequest,
        handleUpdateCompanyRequest,
        handleFetchListRequest,
        handleRequestTokenRequest,
        handleFetchTransactionsRequest,
        handleGenerateMagicLinkRequest,
        setButtonLoading,
        buttonLoading,
        handleClientNotificationRequest,
        handleClientInvitationRequest,
        // handleRegisterEventRequest,
        page,
        totalPerPage,
        searchValue,
        advSearchValue,
        sortOrder,
        sortField,
        // companies,
        dateFrom,
        handleResetFilters,
        handleDeleteCompanyRequest,
    } = useContext(ApiContext)
    const permission = authContext?.user?.permission
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const requestTokenQueries = { code: params.get('code'), state: params.get('state'), realmId: params.get('realmId'), accountId: authContext?.user?.account_id }

    const { data: getRequestToken, isLoading: isRequestTokenLoading } = useQuery({
        queryFn: () => handleRequestTokenRequest(params, handleOnLoadActions),
        queryKey: ['requestToken', requestTokenQueries],
        enabled: Boolean(params.get('code')),
    })

    useQuery({
        queryFn: () => handleFetchCompaniesCountRequest(),
        // queryKey: ['companyCount', companies],
        queryKey: ['companyCount'],
        enabled: !Boolean(params.get('code')) && Boolean(authContext?.user),
    })

    const { data: getCompanies, isLoading: isFetchCompaniesLoading } = useQuery({
        queryFn: () => handleFetchCompaniesRequest(null, handleOnLoadActions),
        queryKey: ['companies', page, totalPerPage, searchValue, advSearchValue, dateFrom, sortField, sortOrder],
        enabled: !Boolean(params.get('code')) && Boolean(authContext?.user),
    })

    const { mutateAsync: mutateCompany } = useMutation({
        mutationFn: handleUpdateCompanyRequest,
        onSuccess: () => queryClient.invalidateQueries(['companies']),
    })

    const { mutateAsync: deleteCompany } = useMutation({
        mutationFn: handleDeleteCompanyRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['companies'])
            queryClient.invalidateQueries(['companyCount'])
        },
    })

    const adminActionButtonCallbacks = {
        notification: handleNotification,
        resendNotification: handleResendNotification,
        navigateSettings: handleNavigateSettings,
        deleteCompany: deleteCompany,
        onLoadActions: handleOnLoadActions,
    }

    async function handleShowStepperModal(step, data) {
        if (step) {
            const fetchListResponse = await handleFetchListRequest(data?.companyId)

            viewContext.modal.show({
                body: <ClientStepper activeStep={step} list={fetchListResponse?.categories} callbacks={{ handleClientInvitation, handleChangeSyncedAccounts }} />,
                overflow: 'hidden',
            })
        } else {
            viewContext.modal.show({
                body: <ClientStepper activeStep={step} callbacks={{ handleClientInvitation, handleChangeSyncedAccounts }} />,
                overflow: 'hidden',
            })
        }
    }

    function checkIfEmailInString(text) {
        const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        return re.test(text)
    }

    async function handleClientInvitation(company, elId) {
        const email = document.getElementById(elId)?.value

        if (!email) {
            return ErrorNotification({ title: 'Invitation', message: 'Email address is required.' })
        }
        if (!checkIfEmailInString(email)) {
            return ErrorNotification({ title: 'Invitation', message: 'Please enter a valid email address.' })
        }

        setButtonLoading(true)
        await handleClientInvitationRequest({
            email,
            permission: 'user',
            companies: [company?._id],
            paymentAccounts: [],
            companyId: company?.id,
        })
        queryClient.invalidateQueries(['companies'])
        // await handleRegisterEventRequest('invited_user', handleOnLoadActions)
        // await mutateCompany({ type: '1', companyId, clientEmail: email, invitedClient: true }, handleOnLoadActions)
        viewContext.modal.hide()
        UpdateNotification({ title: 'Invitation', message: 'Client invited successfully.' })
    }

    async function handleChangeSyncedAccounts(companyId, syncedAccounts) {
        const fetchListResponse = await handleFetchListRequest(companyId)
        const intuitSyncedAccounts = fetchListResponse?.categories?.filter((category) => syncedAccounts.includes(category.value))?.map((cat) => cat.title)
        mutateCompany({ companyId: companyId, intuitSyncedAccounts }, handleOnLoadActions)
    }

    function handleSetSelectedClientDetails(company) {
        if (!company?.intuitEnabled) return handleDisconnectedNotification(company)
        handleFetchCompanyProfileRequest(company?.id)
        navigate(`/company/${company?.id}/transaction`)
    }

    function handleMagicLinkGenerator(email) {
        viewContext.modal.show({ body: <MagicLinkGenerator email={email} /> })
    }

    function handleClientsActivity(type, company) {
        if (permission === 'owner' || permission === 'staff') {
            if (type === 'value') {
                if (company?.editedTransactions.length && company?.intuitEnabled) {
                    return `${company?.editedTransactions.length} Need Review`
                } else if (!company?.editedTransactions.length && company?.transactions && company?.intuitEnabled) {
                    return `${company?.transactions} Uncategorized Transactions`
                } else if (company?.intuitEnabled) {
                    return 'No Action Needed'
                } else if (!company?.intuitEnabled) {
                    return 'Disconnected'
                }
            } else if (type === 'background') {
                if (company?.editedTransactions.length && company?.intuitEnabled) {
                    return 'bg-sky-100'
                } else if (!company?.editedTransactions.length && company?.transactions && company?.intuitEnabled) {
                    return 'bg-red-100'
                } else if (company?.intuitEnabled) {
                    return 'bg-green-100'
                } else if (!company?.intuitEnabled) {
                    return 'bg-inherit'
                }
            } else if (type === 'color') {
                if (company?.editedTransactions.length && company?.intuitEnabled) {
                    return 'text-blue-500'
                } else if (!company?.editedTransactions.length && company?.transactions && company?.intuitEnabled) {
                    return 'text-red-500'
                } else if (company?.intuitEnabled) {
                    return 'text-green-500'
                } else if (!company?.intuitEnabled) {
                    return 'text-red-500'
                }
            }
        } else if (permission === 'user' || permission === 'clientStaff') {
            if (type === 'value') {
                if (company?.transactions) {
                    return `${company?.transactions} Uncategorized Transactions`
                } else if (!company?.transactions) {
                    return 'No Action Needed'
                } else if (!company?.intuitEnabled) {
                    return 'Disconnected'
                }
            } else if (type === 'background') {
                if (company?.transactions) {
                    return 'bg-red-100'
                } else if (!company?.transactions) {
                    return 'bg-green-100'
                } else if (!company?.intuitEnabled) {
                    return 'bg-inherit'
                }
            } else if (type === 'color') {
                if (company?.transactions) {
                    return 'text-red-500'
                } else if (!company?.transactions) {
                    return 'text-green-500'
                } else if (!company?.intuitEnabled) {
                    return 'text-red-500'
                }
            }
        }
    }

    async function handleInviteClient(company, companies) {
        if (!company?.intuitEnabled) return handleDisconnectedNotification(company)

        if (company?.isActive) {
            return UpdateNotification({ title: 'Company Status', message: `${company?.companyName} is already active.` })
        }

        setButtonLoading(true)

        const fetchListResponse = await handleFetchListRequest(company?.id)

        const fetchCompanyProfileResponse = await handleFetchCompanyProfileRequest(company?.id, handleOnLoadActions)

        handleShowInviteClientModal(fetchCompanyProfileResponse, companies, fetchListResponse?.categories)
    }

    function handleShowInviteClientModal(company, companies, categories) {
        const data = categories?.map((list) => ({ value: list.value, label: list.title, children: list.children }))
        const value = data?.filter((d) => company?.intuitSyncedAccounts.some((a) => d.label.includes(a))).map((d) => d.value)

        viewContext.modal.show({
            title: 'Invite Client',
            body: (
                <div className='flex flex-col justify-center gap-4'>
                    <div className='flex flex-col justify-center gap-1'>
                        <div className='flex justify-left items-center gap-1'>
                            <p className='font-bold text-sm !m-0'>Client Email</p>
                            <Tooltip label='Enter a valid email' color='cyan' withArrow>
                                <span>
                                    <Icon image='help-circle' size={14} color='blue' />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <input id='clientInviteEmailAddressAfterOnboarding' className='w-full pl-2 py-3 text-sm outline-none border rounded' type='text' placeholder='Client email...' />
                        </div>
                    </div>

                    <div className='flex flex-col justify-center gap-1 z-auto'>
                        <div className='flex justify-left items-center gap-1'>
                            <span className='text-red-500'>*</span>
                            <p className='inline font-bold text-sm !m-0'>Choose which accounts to sync</p>
                            <Tooltip label='Type of transaction account to be sync from QBO' color='cyan' withArrow>
                                <span>
                                    <Icon image='help-circle' size={14} color='blue' />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <Tree multiple showSearch allowClear placeholder='Choose Account(s)' list={data} value={value} callback={(syncedAccounts) => handleChangeSyncedAccounts(company?.id, syncedAccounts)} />
                        </div>
                    </div>

                    <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Invite Client' width='!w-full' callback={() => handleClientInvitation(company, 'clientInviteEmailAddressAfterOnboarding')} />
                </div>
            ),
            overflow: 'hidden',
        })
    }

    function handleNavigateSettings(company) {
        if (!company?.intuitEnabled) return handleDisconnectedNotification(company)
        navigate(`/company/${company.id}/general-settings`)
    }

    async function handleNotification(company, companies, message) {
        const clientAdmin = company?.clients?.find((client) => client?.permission === 'user')

        if (!company?.intuitEnabled) return handleDisconnectedNotification(company)

        if (!company?.editedTransactions.length && !company?.transactions) {
            return UpdateNotification({ title: 'Notification', message: 'No action is needed, no email will be sent.' })
        }

        viewContext.setLoading(true)

        // Similar functionality with the "Notify Client Now" button.
        await handleFetchTransactionsRequest(company.id)

        if (!company?.isActive) {
            viewContext.setLoading(false)
            return UpdateNotification({ title: 'Info', message: 'Client has not yet activated their account.', icon: <IconInfoCircle />, color: 'red' })
        }

        const generateMgicLinkResponse = await handleGenerateMagicLinkRequest(clientAdmin?.email)

        await handleClientNotificationRequest({ clientEmail: clientAdmin?.email, companyTransactions: company?.transactions, magicLink: generateMgicLinkResponse, message }, handleOnLoadActions)

        UpdateNotification({ title: 'Notification', message: 'Successfully sent.' })
    }

    async function handleResendNotification(company, companies) {
        const clientAdmin = company?.clients?.find((client) => client?.permission === 'user')

        if (!company?.intuitEnabled) return handleDisconnectedNotification(company)
        // setButtonLoading(true)
        await handleClientInvitationRequest(
            {
                email: clientAdmin?.email || company.invitedClientEmail,
            },
            handleOnLoadActions
        )
        // await handleRegisterEventRequest('invited_user', handleOnLoadActions)
        UpdateNotification({ title: 'Resend Invitation', message: 'Client invited successfully.' })
    }

    function handleDisconnectedNotification(company) {
        const message =
            permission === 'owner' || permission === 'staff' ? (
                <span>
                    Company {company?.companyName} has been disconnected from Categorize Me. Click{' '}
                    <span onClick={() => handleShowStepperModal(0)} style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
                        here
                    </span>{' '}
                    to reconnect.
                </span>
            ) : (
                <span>Your company {company?.companyName} has been disconnected from Categorize Me. Please advise your accountant to reconnect the app.</span>
            )
        return ErrorNotification({ title: 'Company connection status', message, icon: <IconInfoCircle /> })
    }

    function CompanyField(props) {
        return (
            <span onClick={() => handleSetSelectedClientDetails(props?.company)}>
                <Link className='text-cyan-500 font-semibold inline-block'>{props?.children}</Link>
            </span>
        )
    }

    function handleFormatCompanyTableData(data) {
        const companies = data ?? []
        let header
        let body

        if (permission === 'owner' || permission === 'staff') {
            header = [
                { name: 'company', title: 'COMPANY', sort: true },
                { name: 'name', title: 'NAME', sort: true },
                { name: 'email', title: 'EMAIL', sort: false },
                { name: 'status', title: 'STATUS', sort: false },
                { name: 'activity', title: 'ACTIVITY', sort: false },
                { name: 'action', title: 'ACTION' },
            ]

            body = companies.map((company) => ({
                company: <CompanyField company={company}>{company.companyName}</CompanyField>,
                name: company?.isActive ? company?.clients?.find((client) => client?.permission === 'user' || client?.permission === 'clientStaff')?.name : '--',
                email: (
                    <div className='flex items-center'>
                        <span className='mr-1'>
                            {company.invitedClient ? (
                                company?.clients?.find((client) => client?.permission === 'user' || client?.permission === 'clientStaff')?.email || company?.invitedClientEmail
                            ) : (
                                <ClientButton width='!w-full' icon='' label='Invite Client' bg='hover:bg-cyan-500 bg-cyan-400' color='text-white' callback={() => handleInviteClient(company, data)} />
                            )}
                        </span>
                        {company.invitedClient && company.isActive && (
                            <Tooltip label='Generate Magic Link' color='cyan' withArrow>
                                <span onClick={() => handleMagicLinkGenerator(company?.clients?.find((client) => client?.permission === 'user' || client?.permission === 'clientStaff')?.email || company?.invitedClientEmail)} className='cursor-pointer'>
                                    <Icon image='star' color='#000' size={20} />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                ),
                status: (
                    <ClientStyledData
                        bg={!company?.invitedClient && !company?.isActive ? 'bg-gray-100' : company?.invitedClient && !company?.isActive ? 'bg-orange-500' : company?.invitedClient && company?.isActive ? 'bg-green-500' : null}
                        c={!company?.invitedClient && !company?.isActive ? 'text-gray-500' : company?.invitedClient && !company?.isActive ? 'text-white' : company?.invitedClient && company?.isActive ? 'text-white' : null}
                    >
                        {!company?.invitedClient && !company?.isActive ? 'NOT YET INVITED' : company?.invitedClient && !company?.isActive ? 'INVITED' : company?.invitedClient && company?.isActive ? 'ACTIVE' : null}
                    </ClientStyledData>
                ),
                activity: (
                    <ClientStyledData bg={handleClientsActivity('background', company)} c={handleClientsActivity('color', company)}>
                        {handleClientsActivity('value', company)}
                    </ClientStyledData>
                ),
                action: <AdminActionButtons callbacks={adminActionButtonCallbacks} company={company} data={companies} />,
            }))
        } else if (permission === 'user' || permission === 'clientStaff') {
            header = [
                { name: 'company', title: 'COMPANY', sort: true },
                { name: 'name', title: 'NAME', sort: true },
                { name: 'activity', title: 'ACTIVITY', sort: true },
            ]

            body = companies.map((company) => ({
                company: <CompanyField company={company}>{company.companyName}</CompanyField>,
                name: company?.clients?.find((client) => client.id === authContext?.user?.accounts[0]?.user_id)?.name,
                activity: (
                    <ClientStyledData bg={handleClientsActivity('background', company)} c={handleClientsActivity('color', company)}>
                        {handleClientsActivity('value', company)}
                    </ClientStyledData>
                ),
            }))
        } else {
            header = []
            body = []
        }

        const tableData = {
            header,
            body,
        }

        return tableData
    }

    function handleOnLoadActions() {
        viewContext.setLoading(false)
        setButtonLoading(false)
        // viewContext.modal.hide()
    }

    /* eslint-disable */
    useEffect(() => {
        if (!isFetchCompaniesLoading) {
            if (!params.get('code') && !getCompanies?.total && (permission === 'owner' || permission === 'staff')) {
                handleShowStepperModal(0)
            }
            viewContext.setLoading(false)
        } else {
            viewContext.setLoading(true)
        }
    }, [isFetchCompaniesLoading])

    useEffect(() => {
        if (params.get('code')) {
            if (!isRequestTokenLoading) {
                if (getRequestToken) {
                    if (!getRequestToken?.isReconnected) {
                        handleShowStepperModal(1, getRequestToken)
                    }
                    navigate('/company')
                }
            } else {
                viewContext.setLoading(true)
            }
        }
        handleResetFilters()
    }, [isRequestTokenLoading])

    return (
        <>
            {isFetchCompaniesLoading ? (
                <Skeleton visible={viewContext.loading} />
            ) : (
                <Animate type='pop'>
                    <LoadingOverlay visible={buttonLoading} overlayBlur={0.5} loaderProps={{ size: 'lg', color: '#06B6D4' }} />
                    {getCompanies?.data?.length > 0 && (
                        <div className='container mx-auto lg:overflow-x-auto xl:overflow-hidden'>
                            <>
                                <div className='p-4 my-6 bg-white shadow rounded lg:overflow-x-auto xl:overflow-hidden'>
                                    <Table sort={true} data={handleFormatCompanyTableData(getCompanies?.data)} />
                                </div>
                                <ClientPagination total={getCompanies?.total} totalPerPage={getCompanies?.totalPerPage} totalResults={getCompanies?.totalResults} />
                            </>
                        </div>
                    )}

                    {!isFetchCompaniesLoading && getCompanies?.data?.length < 1 && (
                        <div style={{ height: '70vh' }} className='flex justify-center items-center my-6 bg-white shadow rounded text-center lg:overflow-x-auto xl:overflow-hidden'>
                            <h1>No client(s) found.</h1>
                        </div>
                    )}
                </Animate>
            )}
        </>
    )
}
