/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import { useState, useEffect, createContext } from 'react'
import { Navigate } from 'react-router-dom'

import Axios from 'utils/axios'
import { useAPI, Event } from 'components/lib'

export const AuthContext = createContext()

const permissions = require('./permissions')

export function AuthProvider(props) {
    const cache = JSON.parse(localStorage.getItem('user'))
    const [user, setUser] = useState(cache)
    const [clients, setClients] = useState()
    const [companies, setCompanies] = useState()
    const [transactions, setTransactions] = useState()
    const [searchValue, setSearchValue] = useState('')
    const [dateFilterFrom, setDateFilterFrom] = useState()
    const [dateFilterTo, setDateFilterTo] = useState()
    const [page, setPage] = useState()
    const [pageSize, setPageSize] = useState()
    const [advSearchPayload, setAdvSearchPayload] = useState()
    const [uncatTrans, setUncatTrans] = useState()
    const auth = useAPI(user ? '/api/v1/auth' : null)

    useEffect(() => {
        if (!auth.loading && auth.data) {
            auth.data?.authenticated ? update(auth.data) : signout()
        }
    }, [auth])

    useEffect(() => {
        if (auth.data) {
            signout()
        }
    }, [])

    async function signin(res) {
        if (res.data) {
            localStorage.setItem('user', JSON.stringify(res.data))
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
            await Event('signin')

            if (!res.data.plan) {
                return (window.location = '/signup/plan')
            } else {
                return (window.location = '/company')
            }
        }
    }

    async function signout() {
        await Axios({ method: 'delete', url: '/api/v1/auth' })
        // localStorage.removeItem('user')
        localStorage.clear()
        return (window.location = '/signin')
    }

    async function switchAccount(id) {
        const res = await Axios({
            method: 'post',
            url: '/api/v1/auth/switch',
            data: { account: id },
        })

        if (res.data) signin(res)
    }

    function update(data) {
        if (localStorage.getItem('user')) {
            let user = JSON.parse(localStorage.getItem('user'))
            for (let key in data) {
                if (Array.isArray(data[key])) {
                    user[key] = data[key]
                } else if (typeof data[key] === 'object') {
                    for (let innerKey in data[key]) {
                        if (user[key]) {
                            user[key][innerKey] = data[key][innerKey]
                        }
                    }
                } else {
                    user[key] = data[key]
                }
            }
            localStorage.setItem('user', JSON.stringify(user))
            setUser(user)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                user: user,
                signin: signin,
                signout: signout,
                update: update,
                switchAccount: switchAccount,
                permission: permissions[user?.permission],
                clients,
                setClients,
                companies,
                setCompanies,
                transactions,
                setTransactions,
                searchValue,
                setSearchValue,
                uncatTrans,
                setUncatTrans,
                dateFilterFrom,
                dateFilterTo,
                setDateFilterFrom,
                setDateFilterTo,
                advSearchPayload,
                setAdvSearchPayload,
                page,
                setPage,
                pageSize,
                setPageSize,
            }}
            {...props}
        />
    )
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
    // check user exists
    const user = JSON.parse(localStorage.getItem('user'))
    const path = window.location.pathname
    const permittedRoutes = ['/account/billing', '/signup/plan', '/account/upgrade', '/account', '/account/profile']

    if (user?.token) {
        if (permissions[user.permission][props.permission]) {
            // user has no plan
            if (!user.plan && path !== '/account/profile' && path !== '/signup/plan') {
                return <Navigate to='/signup/plan' />
            }

            // user has no subscription
            if (user.subscription !== 'active' && user.subscription !== 'trialing' && user.permission !== 'master' && !permittedRoutes.includes(path)) {
                return <Navigate to='/account/billing' />
            }

            // Allowed only users to view the transactions list
            if (user.permission === 'owner' && path === '/transaction') {
                return <Navigate to={-1} />
            }

            // user has not enabled intuit and not onboarded
            /*  if (user.permission === 'owner' && user.plan && path !== '/welcome' && !user.onboarded) {
                return <Navigate to='/welcome' />
            } */

            // user has enabled intuit and onborded
            /* if (user.onboarded && path === '/welcome' && user.intuit_enabled) {
                return <Navigate to={-1} />
            } */

            // user is good
            return props.children
        }
    }

    // user is not authenticated
    return <Navigate to='/signin' />
}
