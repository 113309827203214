import { TPContainer } from 'components/lib'
//import backgroundImage from './images/background-faqs.jpg'

const faqs = [
    [
        {
            question: 'How much does Categorize Me cost?',
            answer: 'After your free trial, you can subscribe to Categorize Me for $2 per client, per month.',
        },
        {
            question: 'What systems does Categorize Me sync with?',
            answer: 'We currently sync with QuickBooks Online. We have future plans to sync with other accounting platforms.',
        },
        {
            question: 'Do you offer training on how to use the system?',
            answer: 'Yes. We have step-by-step tutorials on how to use the sytem as an accountant and as a client.',
        },
        {
            question: 'Is my data and privacy safe?',
            answer: 'Your data is safe with Categorize Me. We’ve passed Intuit’s rigorous security and technical reviews. We have implemented robust security measures at every layer of our system. You can trust Categorize Me to protect your sensitive information.',
        },
        
    ],
    [
        {
            question: 'Can I invite my co-workers to the system?',
            answer: 'Yes, as an accountant you an invite an unlimited amount of team members to your account.',
        },
        {
            question: 'How are clients notified of uncategorized transactions?',
            answer: 'Clients will automatically be notified if there are uncategorized transactions that need their attention. You can customize the notification frequency in the client settings. You also will have the ability to manually trigger an email to the client as needed.',
        },
        {
            question: 'How do I know when transactions have been updated by a client?',
            answer: 'You will receive an email from Categorize Me with a list of the clients who have updated transactions. The transactions that have been updated will be listed as Needs Attention when you log in.',
        },
        {
            question: 'What fields are available for editing in Categorize Me?',
            answer: 'Categorize Me lets you edit customer, vendor, memo/description, category, billable status, tax, tax rate, class, and location information. You can also attach receipts and split transactions.',
        },
    ],
    [
        {
            question: 'Can I upload attachments to each transaction?',
            answer: 'Yes, both accountants and clients can upload documents to transactions. The files will be saved directly to QuickBooks Online.',
        },
        {
            question: 'Do my clients need to download anything to work with Categorize Me?',
            answer: 'No. All your clients need is an invitation to access the platform, and they can seamlessly add transaction details and attachments. Make it easy for your clients to manage their uncategorized transactions with Categorize Me.',
        },
        {
            question: 'Can I request a feature to be added?',
            answer: 'Yes, please do! Send us an email at help@categorize.me and we will add your request to our new feature queue.',
        },
    ],
]

export function TPFaqs() {
    return (
        <section id='faq' aria-labelledby='faq-title' className='relative overflow-hidden bg-slate-50 py-20 sm:py-32'>
            <TPContainer className='relative'>
                <div className='mx-auto max-w-2xl lg:mx-0'>
                    <h2 id='faq-title' className='font-["Lexend"] text-3xl tracking-tight text-slate-900 sm:text-4xl'>
                        Frequently Asked Questions
                    </h2>
                    <p className='mt-4 text-lg tracking-tight text-slate-700'>
                        If you can’t find what you’re looking for, <a href='mailto:help@Categorize.Me'>email our support team</a> and we'll get back to you as soon as possbile.
                    </p>
                </div>
                {/* eslint-disable */}
                <ul role='list' className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3'>
                    {faqs.map((column, columnIndex) => (
                        <li key={columnIndex}>
                            <ul role='list' className='flex flex-col gap-y-8'>
                                {column.map((faq, faqIndex) => (
                                    <li key={faqIndex}>
                                        <h3 className='font-["Lexend"] text-lg leading-7 text-slate-900'>{faq.question}</h3>
                                        <p className='mt-4 text-sm text-slate-700'>{faq.answer}</p>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </TPContainer>
        </section>
    )
}
