/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/
import React, { Fragment, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { MultiSelect, Select, TextInput } from '@mantine/core'

import { ApiContext } from 'context/api'
import { ViewContext, Card, Table, Button, Animate, TitleRow, useAPI, usePermissions, Event, ClientButton, AuthContext, AccountNav } from 'components/lib'
import { UpdateNotification, ErrorNotification } from 'utils/notifications'
import { IconInfoCircle } from '@tabler/icons-react'

export function AccountingFirmStaff(props) {
    const viewContext = useContext(ViewContext)
    const authContext = useContext(AuthContext)
    const { handleFetchCompaniesRequest, handleEditStaffCompanyListRequest, handleFetchStaffUserAccountRequest, setButtonLoading, handleInviteStaffRequest, companyProfile } = useContext(ApiContext)
    const permissions = usePermissions()
    const permission = authContext?.user?.permission

    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    const id = companyId === ':companyId' ? companyProfile?.id : companyId

    const data = useAPI('/api/v1/account/users')

    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([])

    function handleRemoveAddUserLSData() {
        localStorage.removeItem('addStaffPayloadEmail')
        localStorage.removeItem('addStaffPayloadPermission')
        localStorage.removeItem('addStaffPayloadCompanies')
    }

    function handleCloseAddUserModal() {
        viewContext.modal.hide()
        handleRemoveAddUserLSData()
    }

    function handleAddUserOnchange(props, field) {
        if (field === 'email') {
            localStorage.setItem('addStaffPayloadEmail', props.target.value)
        } else if (field === 'permission') {
            localStorage.setItem('addStaffPayloadPermission', props)
        } else if (field === 'companies') {
            localStorage.setItem('addStaffPayloadCompanies', JSON.stringify(props))
        }
    }

    async function handleAddUserRequest() {
        setButtonLoading(true)
        const payload = {
            email: localStorage.getItem('addStaffPayloadEmail'),
            permission: localStorage.getItem('addStaffPayloadPermission') || permission === 'user' ? 'clientStaff' : 'staff',
            companies: JSON.parse(localStorage.getItem('addStaffPayloadCompanies')) || [],
            companyId: id,
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (payload.email && !re.test(payload.email)) {
            return ErrorNotification({
                title: 'Invalid email',
                message: 'Please enter a valid email address',
                icon: <IconInfoCircle />,
            })
        }
        await handleInviteStaffRequest(payload, handleAddUserRequestCallback)
    }

    async function handleAddUserRequestCallback(data) {
        if (data.length) {
            viewContext.modal.hide()
            const state = [...users]

            data.forEach((invite) => {
                if (!state.find((x) => x.id === invite.id)) {
                    state.push({
                        id: invite.id,
                        name: '',
                        email: invite.email,
                        date_created: invite.date_sent,
                        permission: invite.permission === 'clientStaff' ? 'client staff' : invite.permission || 'user',
                        status: 'Invited',
                        actions: {
                            invite: resendInvite,
                            delete: deleteInvite,
                        },
                    })
                }
            })

            Event('invited_user')
            setUsers(state)
        } else {
            return ErrorNotification({
                title: 'Notification',
                message: data.response.data.message,
                icon: <IconInfoCircle />,
            })
        }
    }

    function invite() {
        viewContext.modal.show({
            title: 'Add Staff',
            body: (
                <div className='flex flex-col justify-center pb-4 gap-5'>
                    <p>To invite more than one user, seperate the emails with a comma</p>
                    <div>
                        <p className='!m-0'>
                            Email <span className='text-red-500'>*</span>
                        </p>
                        <TextInput defaultValue='' placeholder='Email...' onChange={(e) => handleAddUserOnchange(e, 'email')} required />
                    </div>
                    <div>
                        <p className='!m-0'>
                            Permission <span className='text-red-500'>*</span>
                        </p>
                        <Select
                            styles={{ defaultValueLabel: { overflow: 'unset' } }}
                            placeholder='Permission...'
                            defaultValue={permission === 'user' ? 'clientStaff' : 'staff'}
                            data={permissions?.data?.list?.filter((x) => x.value !== 'admin' && x.value !== 'owner' && x.value !== 'user' && x.value !== (permission === 'user' ? 'staff' : 'clientStaff'))}
                            onChange={(e) => handleAddUserOnchange(e, 'permission')}
                            dropdownPosition='top'
                        />
                    </div>
                    <div>
                        <p className='!m-0'>Assigned Client(s)</p>
                        <MultiSelect
                            styles={{ defaultValueLabel: { overflow: 'unset' }, input: { maxHeight: '300px', overflowY: 'scroll', borderRadius: 'unset' } }}
                            searchable
                            placeholder='Add company'
                            nothingFound='Nothing found'
                            data={companies}
                            onChange={(companies) => handleAddUserOnchange(companies, 'companies')}
                            dropdownPosition='top'
                        />
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                        <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Save' width='!w-full' callback={handleAddUserRequest} />
                        <ClientButton bg='bg-red-500' color='text-white' icon='' label='Cancel' width='!w-full' callback={handleCloseAddUserModal} />
                    </div>
                </div>
            ),
        })
    }

    function handleRemoveEditUserLSData() {
        localStorage.removeItem('editPayloadName')
        localStorage.removeItem('editPayloadEmail')
        localStorage.removeItem('editPayloadPermission')
        localStorage.removeItem('editPayloadCompanies')
    }

    function handleCloseEditUserModal() {
        viewContext.modal.hide()
        handleRemoveEditUserLSData()
    }

    function handleEditUserOnchange(props, field) {
        if (field === 'name') {
            localStorage.setItem('editPayloadName', props.target.value)
        } else if (field === 'email') {
            localStorage.setItem('editPayloadEmail', props.target.value)
        } else if (field === 'companies') {
            localStorage.setItem('editPayloadCompanies', JSON.stringify(props))
        }
    }

    async function handleEditUserRequest(data) {
        const { id } = data
        viewContext.modal.hide()
        setButtonLoading(true)
        const payload = {
            id,
            name: localStorage.getItem('editPayloadName') || data.name,
            email: localStorage.getItem('editPayloadEmail') || data.email,
            companies: JSON.parse(localStorage.getItem('editPayloadCompanies')) || data.companies,
        }
        await handleEditStaffCompanyListRequest(payload)
        const fetchStaffUserAccount = await handleFetchStaffUserAccountRequest(handleOnLoadActions)
        handleFormatUserList(fetchStaffUserAccount?.data)
    }

    function editUser(data, callback) {
        viewContext.modal.show({
            title: 'Update User',
            body: (
                <div className='flex flex-col justify-center pb-4 gap-5'>
                    <div>
                        <p className='!m-0'>
                            Name <span className='text-red-500'>*</span>
                        </p>
                        <TextInput defaultValue={data.name} placeholder='Name...' onChange={(e) => handleEditUserOnchange(e, 'name')} />
                    </div>
                    <div>
                        <p className='!m-0'>
                            Email <span className='text-red-500'>*</span>
                        </p>
                        <TextInput defaultValue={data.email} placeholder='Email...' onChange={(e) => handleEditUserOnchange(e, 'email')} />
                    </div>
                    {(data.permission === 'staff' || permission === 'user') && (
                        <>
                            <div>
                                <p className='!m-0'>Assigned Client(s)</p>
                                <MultiSelect
                                    styles={{ defaultValueLabel: { overflow: 'unset' }, input: { maxHeight: '300px', overflowY: 'scroll', borderRadius: 'unset' } }}
                                    searchable
                                    defaultValue={data.companies.map((data) => data._id)}
                                    placeholder='Add company'
                                    nothingFound='Nothing found'
                                    data={companies}
                                    onChange={(companies) => handleEditUserOnchange(companies, 'companies')}
                                    dropdownPosition='top'
                                />
                            </div>
                        </>
                    )}
                    <div className='flex justify-center items-center gap-2'>
                        <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Save' width='!w-full' callback={() => handleEditUserRequest(data)} />
                        <ClientButton bg='bg-red-500' color='text-white' icon='' label='Cancel' width='!w-full' callback={handleCloseEditUserModal} />
                    </div>
                </div>
            ),
            overflow: 'hidden',
        })
    }

    function deleteUser(data, callback) {
        viewContext.modal.show(
            {
                title: 'Delete User',
                form: {},
                buttonText: 'Delete User',
                text: `Are you sure you want to delete ${data.name}?`,
                url: `/api/v1/user/${data.id}`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                // viewContext.notification.show(data.name + ' was deleted', 'success', true)
                UpdateNotification({ title: 'Delete User', message: `${data.name} was deleted.` })
                callback()
            }
        )
    }

    function deleteInvite(data, callback) {
        viewContext.modal.show(
            {
                title: 'Delete Invite',
                form: {},
                buttonText: 'Delete Invite',
                text: `Are you sure you want to delete the invite for ${data.email}?`,
                url: `/api/v1/invite/${data.id}`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                const state = [
                    ...users.splice(
                        users.find((x) => x.id === data.id),
                        1
                    ),
                ]
                UpdateNotification({ title: 'Delete Invite', message: `${data.email}'s invite was deleted.` })
                setUsers(state)
            }
        )
    }

    async function resendInvite(data) {
        try {
            await axios({
                url: '/api/v1/invite',
                method: 'post',
                data: { email: data.email },
            })

            viewContext.notification.show(`Invite re-sent to ${data.email}`, 'success', true)
        } catch (err) {
            viewContext.handleError(err)
        }
    }

    async function handleFetchCompanies() {
        const fetchCompaniesResponse = await handleFetchCompaniesRequest(1)
        const list = fetchCompaniesResponse?.data?.map((company) => ({ value: company._id, label: company.companyName }))
        setCompanies(list)
    }

    async function handleFormatUserList(userPayload) {
        let list = []
        if (userPayload?.data?.users?.length) {
            for (let i = 0; i < userPayload.data.users.length; i++) {
                if (userPayload.data.users[i].permission === (permission === 'owner' ? 'staff' : 'clientStaff')) {
                    list.push({
                        id: userPayload.data.users[i].id,
                        name: userPayload.data.users[i].name,
                        email: userPayload.data.users[i].email,
                        date_created: userPayload.data.users[i].date_created,
                        permission: userPayload.data.users[i].permission === 'clientStaff' ? 'client staff' : 'staff',
                        companies: userPayload.data.users[i].companies,
                        status: 'Registered',
                    })
                }
            }
        }

        if (userPayload?.data?.invites?.length) {
            userPayload.data.invites.forEach((x) => {
                if (x.permission === (permission === 'owner' ? 'staff' : 'clientStaff'))
                    list.push({
                        id: x.id,
                        name: '',
                        email: x.email,
                        date_created: x.date_sent,
                        permission: x.permission === 'clientStaff' ? 'client staff' : 'staff',
                        status: 'Invited',
                    })
            })
        }

        setUsers(list)
    }

    function handleOnLoadActions() {
        setButtonLoading(false)
    }

    /* eslint-disable */
    useEffect(() => {
        // Fetch companies
        handleFetchCompanies()

        // format the user list
        let list = []

        if (data?.data?.users?.length) {
            for (let i = 0; i < data.data.users.length; i++) {
                if (data.data.users[i].permission === (permission === 'user' ? 'clientStaff' : 'staff')) {
                    list.push({
                        id: data.data.users[i].id,
                        name: data.data.users[i].name,
                        email: data.data.users[i].email,
                        date_created: data.data.users[i].date_created,
                        permission: data.data.users[i].permission === 'clientStaff' ? 'client staff' : data.data.users[i].permission,
                        companies: data.data.users[i].companies,
                        status: 'Registered',
                    })
                }
            }
        }

        if (data?.data?.invites?.length) {
            data.data.invites.forEach((x) => {
                if (x.permission === (permission === 'user' ? 'clientStaff' : 'staff')) {
                    list.push({
                        id: x.id,
                        name: '',
                        email: x.email,
                        date_created: x.date_sent,
                        permission: x.permission === 'clientStaff' ? 'client staff' : x.permission || 'user',
                        status: 'Invited',
                    })
                }
            })
        }

        setUsers(list)
    }, [data])

    // attach the per row actions for invites
    if (users.length) {
        users.forEach((u) => {
            if (u.status === 'Invited') {
                u.actions = {
                    invite: resendInvite,
                    delete: deleteInvite,
                }
            } else if (u.status === 'Registered') {
                u.actions = {
                    edit: editUser,
                    email: true,
                    delete: deleteUser,
                }
            }
        })
    }

    return (
        <Fragment>
            <AccountNav />
            <Animate>
                <TitleRow title='Manage Staff'>
                    <Button small text='Add Staff' action={invite} />
                </TitleRow>

                {users?.length ? (
                    <Card>
                        <Table
                            search
                            className='restrict-width'
                            data={users}
                            loading={data.loading}
                            // show={['name', 'email', 'date_created', 'last_login', 'permission', 'status']}
                            show={['name', 'email', 'last_login', 'permission', 'status']}
                            badge={{
                                col: 'status',
                                color: 'blue',
                                condition: [
                                    { value: 'registered', color: 'green' },
                                    { value: 'invited', color: 'blue' },
                                ],
                            }}
                            // actions={{ edit: editUser, email: true, delete: deleteUser }}
                        />
                    </Card>
                ) : (
                    <div className='flex justify-center items-center' style={{ height: '50vh' }}>
                        <p>No available users.</p>
                    </div>
                )}
            </Animate>
        </Fragment>
    )
}
