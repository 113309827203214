import { Fragment, useContext, useEffect } from 'react'
import { Skeleton } from '@mantine/core'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'

import { Animate, ClientButton, SettingsNav, Card, ViewContext, useNavigate } from 'components/lib'
import { ApiContext } from 'context/api'

export function OtherSettings(props) {
    const viewContext = useContext(ViewContext)
    const { handleFetchCompanyProfileRequest, companyProfile, handleClientRevokeAccessRequest, handleSyncTransactionsRequest } = useContext(ApiContext)
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    const id = companyId === ':companyId' ? companyProfile?.id : companyId
    const queryClient = useQueryClient()

    function handleOnLoadActions(data) {
        viewContext.setLoading(false)
    }

    const { isSuccess: isFetchCompanyProfileSuccess } = useQuery({
        queryFn: () => handleFetchCompanyProfileRequest(id, handleOnLoadActions),
        queryKey: ['companyProfileInOtherSettings'],
    })

    const { mutateAsync: mutateClientDisconnection, isSuccess: isClientDisconnectionSuccess } = useMutation({
        mutationFn: handleClientRevokeAccessRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['companies'])
        },
    })

    function handleClientDisconnection() {
        viewContext.setLoading(true)
        mutateClientDisconnection(id, handleOnLoadActions)
    }

    async function handleSyncTransactions() {
        viewContext.setLoading(true)
        return await handleSyncTransactionsRequest(id, handleOnLoadActions)
    }

    /* eslint-disable */
    useEffect(() => {
        if (isClientDisconnectionSuccess) {
            if (mutateClientDisconnection) {
                navigate('/company')
            }
        }
    }, [isClientDisconnectionSuccess])

    /* eslint-disable */
    useEffect(() => {
        navigate(`/company/${id}/other-settings`)
        if (!isFetchCompanyProfileSuccess) {
            viewContext.setLoading(true)
        } else viewContext.setLoading(false)
    }, [isFetchCompanyProfileSuccess])

    return (
        <>
            <SettingsNav companyId={id} />
            {viewContext.loading ? (
                <Skeleton visible={viewContext.loading} />
            ) : (
                <Animate type='pop'>
                    <Card restrictWidth>
                        <div className='w-full flex flex-col justify-center gap-2'>
                            <div className='flex flex-col justify-center gap-4'>
                                <div className='w-auto flex flex-col justify-center gap-1'>
                                    <ClientButton disabled={!companyProfile?.intuitEnabled} bg={!companyProfile?.intuitEnabled ? 'bg-green-300' : 'bg-green-500'} icon='refresh-cw' color='text-white' label='Sync Transactions' callback={handleSyncTransactions} />
                                </div>
                                <div className='w-auto flex flex-col justify-center gap-1'>
                                    <ClientButton disabled={!companyProfile?.intuitEnabled} bg={!companyProfile?.intuitEnabled ? 'bg-red-300' : 'bg-red-500'} icon='user-x' color='text-white' label='Disconnect Client' callback={handleClientDisconnection} />
                                </div>
                            </div>
                        </div>
                    </Card>
                </Animate>
            )}
        </>
    )
}
